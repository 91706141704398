<template>
	<div>
        <v-row v-if="!loading && !permissions.can_list">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <p class="text-h5 mb-4">Attendance Photo Info</p>
                <p class="text-title mb-0">You do not have permission to view attendance photo info</p>
            </v-col>
        </v-row>
        <template v-if="permissions.can_list">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-sheet color="white" elevation="0" class="pa-0">
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <p class="text-h4 mb-0" v-text="object.name"></p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <display-label label="Client" :text="object.client.text"></display-label>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
           <v-row v-if="permissions.can_list">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-sheet color="white" elevation="0" class="pa-0">
                        <p class="text-h6 mb-4">Search</p>
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <v-menu offset-y transition="scale-transition" min-width="auto" color="primary" :close-on-content-click="false" v-model="workDateMenu">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense clearable label="Date of Activation" v-bind="attrs" v-on="on" v-model="filters.work_date"></v-text-field>
                                    </template>
                                    <v-date-picker no-title color="primary" v-model="filters.work_date" @input="workDateMenu = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" order="last">
                                <v-btn small color="primary" class="mr-2 elevation-0" :ripple="false" @click='search'>
                                    <v-icon small left>mdi-magnify</v-icon> Search
                                </v-btn>
                                <v-btn small class="elevation-0" :ripple="false" @click='reset'>
                                    <v-icon small left>mdi-undo</v-icon> Reset
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="items.length > 0">
                    <v-btn small color="primary" class="mr-2 mt-2 elevation-0" :ripple="false" @click="openDownloadDialog">
                        <v-icon small left>mdi-download</v-icon> Download
                    </v-btn>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-data-table hide-default-footer class="elevation-0" loading-text="Loading... Please wait..." no-data-text="No data available yet" :loading="loading" :items-per-page="50" :headers="headers" :items="items">
                        <template v-slot:item='{ item, index }'>
                            <tr>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="index + 1"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
                                    <v-img width="100" class="mx-auto my-3 pointer" :src="item.thumbnail_url" @click="openImageDialog(item.image_url)" v-if="item.thumbnail_url"></v-img>
                                </td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.campaign"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.outlet"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.work_date"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.start_time"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.end_time"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.check_time"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.photo_time"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.account.name"></td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </template>
        <image-dialog title="Attendance Photo" ref="imageDialog"></image-dialog>
		<download-dialog ref="downloadDialog"></download-dialog>
		<message-notifier ref="notifier"></message-notifier>
	</div>
</template>


<script>

import AttendancePhotoDownloadDialog from './AttendancePhotoDownloadDialog'
import MessageNotifier from '../../../../../shared/MessageNotifier.vue'
import DisplayLabel from '../../../../../shared/DisplayLabel.vue'
import ImageDialog from '../../../../../shared/ImageDialog.vue'

export default {
	name: 'AttendancePhotoReportInfo',
	components: {
        messageNotifier: MessageNotifier,
        displayLabel: DisplayLabel,
        downloadDialog: AttendancePhotoDownloadDialog,
        imageDialog: ImageDialog,
	},
	data () {
		return {
			loading: false,
			workDateMenu: null,
			selectedItems: {},
			selectedItemIds: [],
            filters: {
                work_date: '',
            },
			object: {
                client: {},
                name: '',
			},
            permissions: {
                can_list: false,
                can_download: false
            }
		}
	},
	computed: {
        imageDialog() {
            return this.$refs.imageDialog
        },
        downloadDialog() {
            return this.$refs.downloadDialog
        },
        notifier() {
            return this.$refs.notifier
        }
	},
	mounted: function() {
        this.get()
	},
	methods: {
        get: function() {
			this.loading = true
			const filters = { project: this.$route.params.id, work_date: this.filters.work_date?this.filters.work_date:'' }
			this.$store.dispatch('epanel/report/getReportAttendancePhotoInfo', filters).then((response) => {
                this.loading = false
                this.object = response.data.object
                this.headers = response.data.headers
                this.items = response.data.items
                this.permissions = response.data.permissions
			}).catch(() => {
				this.loading = false
			})
		},
		search: function() {
            this.filters.page = 1
            this.get()
        },
        reset: function() {
            this.filters = { project: this.$route.params.id, work_date: '', page: 1}
        },
		openDownloadDialog: function() {
            if(this.permissions.can_download){
                this.downloadDialog.updateObject({ work_date: this.filters.work_date })
                this.downloadDialog.open()
            }
        },
        openImageDialog: function(imageUrl) {
            this.imageDialog.updateImageUrl(imageUrl)
            this.imageDialog.open()
        },
		openNotifier: function(message) {
            this.notifier.updateText(message)
            this.notifier.open()
        }
	}
}

</script>